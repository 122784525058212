import { cloneDeep, unset } from 'lodash';

import { DRAFTS, SIGN_OUT } from '../actions/constants';

const initialState = {
  backup: {},
  data: {},
  handling: false,
};

export default function drafts(state = initialState, action) {
  const { type, data, id } = action;

  const oldData = state.data ?? {};

  switch (type) {
    case DRAFTS.handlers.get:
      return {
        backup: {},
        data: {},
        handling: true,
      };
    case DRAFTS.handlers.save:
    case DRAFTS.handlers.documents:
      return {
        ...state,
        handling: true,
      };
    case DRAFTS.reload:
      return {
        backup: cloneDeep(data),
        data,
        handling: false,
      };
    case DRAFTS.update:
      if (id) {
        if (data) {
          return {
            ...state,
            data: {
              ...oldData,
              [id]: {
                ...(oldData?.[id] ?? {}),
                ...data,
              },
            },
            handling: false,
          };
        }
        unset(oldData, id);
        return {
          ...state,
          data: {
            ...oldData,
          },
          handling: false,
        };
      }
      return {
        ...state,
        handling: false,
      };
    case DRAFTS.undo:
      return {
        ...state,
        data: {
          ...oldData,
          [id]: cloneDeep(state?.backup?.[id] ?? {}),
        },
      };
    case DRAFTS.done:
      return {
        ...state,
        handling: false,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
