import { Chip, Notification, Waiting } from '@xbotvn/mui';
import { saveAs } from 'file-saver';
import FileType from 'get-file-type-es5';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { callFileAPI } from '../../libs/backend';

function FileTag({ onRemove, id, name, forceName }) {
  const unitID = useSelector(({ user }) => user?.unit?.id ?? '');
  const [downloading, setDownloading] = useState(false);

  if (downloading) {
    return <Waiting />;
  }
  return (
    <Chip
      color={id ? 'primary' : 'warning'}
      onDelete={
        onRemove
          ? (e) => {
              onRemove();
              e.stopPropagation();
            }
          : undefined
      }
      onClick={() => {
        if (id) {
          setDownloading(true);
          callFileAPI('download', { id, unitID }, true)
            .then(async (data) => {
              setDownloading(false);
              const blobData = new Blob([data]);
              const bufferData = await blobData.arrayBuffer();
              const extension = FileType(new Uint8Array(bufferData))?.ext ?? blobData.type;
              saveAs(blobData, `${name}.${extension}`);
            })
            .catch(({ message }) => {
              setDownloading(false);
              Notification.warn(`Tải file không thành công: ${message}`);
            });
        }
      }}
      label={id && !forceName ? 'Tải về' : name}
    />
  );
}

FileTag.propTypes = {
  onRemove: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  forceName: PropTypes.bool,
};

FileTag.defaultProps = {
  onRemove: undefined,
  id: '',
  name: '',
  forceName: false,
};

export default FileTag;
