import { Notification } from '@xbotvn/mui';
import { addDays, startOfDay } from 'date-fns';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { graphQLCaller } from '../../libs/backend';
import { NEWS } from './constants';

export function* updateDocuments(documents = []) {
  yield put({
    type: NEWS.documents,
    documents,
  });
}
export function* updateTags(tags = []) {
  yield put({
    type: NEWS.tags,
    tags,
  });
}

function* getDocuments() {
  const option = (yield select())?.news?.settings?.documents ?? 'today';
  let from = '';
  try {
    switch (option) {
      case 'yesterday':
        from = startOfDay(addDays(new Date(), -1)).getTime();
        break;
      case 'last7days':
        from = startOfDay(addDays(new Date(), -7)).getTime();
        break;
      case 'last30days':
        from = startOfDay(addDays(new Date(), -30)).getTime();
        break;
      default:
    }
    const id = (yield select())?.user?.unit?.id ?? '';
    if (id) {
      const { recentDocuments } = yield graphQLCaller(
        'documents',
        `{
          recentDocuments(unitID: "${id}"${from ? `, from: ${from}` : ''}) {
            id
            unit
            docType
            category
            department
            docNo
            other
            authority
            date
            summary
            tags
            file
            publicFields
            classified
            isPublic
          }
        }`
      );
      yield* updateDocuments(recentDocuments || []);
      const { mostSearchedTags } = yield graphQLCaller(
        'tags',
        `{
            mostSearchedTags(unitID: "${id}") {
              tag
              times
          }
        }`
      );
      yield* updateTags(mostSearchedTags || []);
    } else yield* updateDocuments();
  } catch ({ message }) {
    Notification.error(message);
    yield* updateDocuments();
  }
}

function* updateSettings() {
  yield put({ type: NEWS.handlers.documents });
}

export const handleUpdateSettings = (settings) => ({
  type: NEWS.handlers.settings,
  settings,
});

export const handleGetDocuments = () => ({ type: NEWS.handlers.documents });

export default function* saga() {
  yield all([
    yield takeEvery(NEWS.handlers.settings, updateSettings),
    yield takeLatest(NEWS.handlers.documents, getDocuments),
  ]);
}
