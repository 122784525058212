import UilMultiply from '@iconscout/react-unicons/icons/uil-multiply';
import { Box, IconButton } from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React from 'react';

function Signature({ onRemove, signature }) {
  return (
    <Box sx={{ height: 150, margin: 'auto' }}>
      <img src={signature} height="100%" width="100%" alt="signature" />
      {onRemove ? (
        <IconButton sx={{ position: 'absolute', right: 20 }} onClick={onRemove}>
          <UilMultiply size={18} />
        </IconButton>
      ) : null}
    </Box>
  );
}

Signature.propTypes = {
  onRemove: PropTypes.func,
  signature: PropTypes.string.isRequired,
};

Signature.defaultProps = {
  onRemove: undefined,
};

export default Signature;
