import { Notification } from '@xbotvn/mui';
import { cloneDeep } from 'lodash';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { graphQLCaller } from '../../libs/backend';
import { DRAFTS } from './constants';

function* update(id, data) {
  yield put({
    type: DRAFTS.update,
    id,
    data,
  });
}

function* reload(data = []) {
  const converted = {};
  data.forEach((draft) => {
    const { id, ...rest } = draft;
    converted[id] = rest;
  });
  yield put({
    type: DRAFTS.reload,
    data: converted,
  });
}

export const handleGetDrafts = (department = '') => ({
  type: DRAFTS.handlers.get,
  department,
});

function* insertDocuments({ department }) {
  const data = (yield select())?.drafts?.data ?? {};
  const unitID = (yield select())?.user?.unit?.id ?? '';
  if (unitID) {
    try {
      const drafts = {};
      const documents = [];
      Object.entries(data).forEach(([id, doc]) => {
        if (!doc.status) {
          const { file, docType, docNo, category, date, user, ...rest } = doc;
          if (docType && docNo && category && date) {
            const tmp = {
              unit: unitID,
              docType,
              docNo,
              category,
              date,
              ...rest,
              draft: id,
            };
            if (file) tmp.file = file;
            tmp.author = {
              user,
              time: new Date().getTime(),
            };
            documents.push(tmp);
          }
        } else drafts[id] = doc;
      });
      yield graphQLCaller(
        'documents',
        `
        mutation insertDocuments($unitID: String!, $department: String!, $documents: [DocumentInput]!) {
          insertDocuments(unitID: $unitID, department: $department, documents: $documents)
        }
      `,
        {
          unitID,
          department,
          documents,
        }
      );
      yield* update();
      Notification.success('Cập nhật văn bản thành công.');
      yield put(handleGetDrafts(department));
    } catch ({ message }) {
      yield* update();
      Notification.error(message);
    }
  } else yield* update();
}

function* getDrafts({ department }) {
  const unitID = (yield select())?.user?.unit?.id ?? '';

  try {
    const { drafts } = yield graphQLCaller(
      'drafts',
      `{
        drafts(unitID: "${unitID}", department: "${department}") {
          id
          docType
          category
          department
          user
          docNo
          authority
          summary
          date
          file
          signed
          isPublic
          secure
          expiredDate
          handler
        }
      }`
    );
    yield* reload(drafts || {});
  } catch ({ message }) {
    Notification.error(message);
    yield* reload();
  }
}

function* updateDraft({ id, data, settings }) {
  const oldStatus = (yield select())?.drafts?.data?.[id]?.status ?? '';
  const clone = cloneDeep(data);
  if (oldStatus === 'inserted' && clone.status === 'deleted') {
    yield* update(id);
  } else {
    Object.entries(settings || {}).forEach(([field, value]) => {
      if (value && !clone[field]) clone[field] = value;
    });
    if (!clone.status && !['deleted', 'inserted'].includes(oldStatus)) {
      clone.status = 'changed';
    }
    yield* update(id, clone);
  }
}

function* saveDrafts({ localDep }) {
  const unitID = (yield select())?.user?.unit?.id ?? '';
  const data = (yield select())?.drafts?.data ?? {};
  if (unitID) {
    try {
      const updates = [];
      const inserts = [];
      Object.entries(data).forEach(([id, draft]) => {
        // eslint-disable-next-line unused-imports/no-unused-vars
        const { status, department, user, ...rest } = draft;
        switch (status) {
          case 'changed':
            updates.push({
              id,
              department,
              ...rest,
            });
            break;
          case 'deleted':
            updates.push({ id, department });
            break;
          case 'inserted':
            inserts.push({ department, ...rest });
            break;
          default:
        }
      });
      if (updates.length) {
        yield graphQLCaller(
          'drafts',
          `
      mutation updateDrafts($unitID: String!, $department: String!, $drafts: [DraftInput]!) {
        updateDrafts(unitID: $unitID, department: $department, drafts: $drafts)
      }
    `,
          {
            unitID,
            department: localDep,
            drafts: updates,
          }
        );
      }
      if (inserts.length) {
        yield graphQLCaller(
          'drafts',
          `
      mutation insertDrafts($unitID: String!, $department: String!, $drafts: [DraftInput]!) {
        insertDrafts(unitID: $unitID, department: $department, drafts: $drafts) {
          id
        }
      }
    `,
          {
            unitID,
            department: localDep,
            drafts: inserts,
          }
        );
      }
      yield* update();
      Notification.success('Cập nhật thành công.');
      yield put(handleGetDrafts(localDep));
    } catch ({ message }) {
      yield* update();
      Notification.error(message);
    }
  } else yield* update();
}

export const handleSaveDrafts = (localDep) => ({
  type: DRAFTS.handlers.save,
  localDep,
});

export const handleUpdateDraft = (id, data, settings = {}) => ({
  type: DRAFTS.handlers.update,
  id,
  data,
  settings,
});

export const handleUndoDrafts = () => ({
  type: DRAFTS.undo,
});

export const handleInsertDocuments = (department) => ({
  type: DRAFTS.handlers.documents,
  department,
});

export default function* saga() {
  yield all([
    yield takeLatest(DRAFTS.handlers.get, getDrafts),
    yield takeLatest(DRAFTS.handlers.save, saveDrafts),
    yield takeEvery(DRAFTS.handlers.update, updateDraft),
    yield takeLatest(DRAFTS.handlers.documents, insertDocuments),
  ]);
}
