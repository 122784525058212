import { DOCUMENT, SIGN_OUT } from '../actions/constants';

const initialState = {};

export default function document(state = initialState, action) {
  const { type, id, data } = action;

  switch (type) {
    case DOCUMENT.handlers.get:
      return {
        id,
        data: {},
        handling: true,
      };
    case DOCUMENT.handlers.update:
      return {
        ...state,
        handling: true,
      };
    case DOCUMENT.update:
      return {
        ...state,
        data: {
          ...(state?.data ?? {}),
          ...data,
        },
        handling: false,
      };
    case DOCUMENT.clear:
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
