import { Box, Notification, Pagination, Typography } from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';

import { callFileAPI } from '../../libs/backend';

function FileViewer({ id, local, height, width }) {
  const { unitID, department } = useSelector(({ user, document }) => ({
    unitID: user?.unit?.id ?? '',
    department: document?.data?.department ?? '',
  }));

  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [file, setFile] = useState();

  useEffect(() => {
    if (local) {
      const reader = new FileReader();
      reader.onload = () => {
        setFile(reader.result);
      };
      reader.readAsDataURL(local);
    } else if (id) {
      callFileAPI('download', { id, unitID, department }, true)
        .then((data) => {
          setFile(data);
        })
        .catch(({ message }) => Notification.warn(`Tải file không thành công: ${message}`));
    } else setFile();
  }, [id, local]);

  if (file)
    return (
      <Box>
        <Box sx={{ height, overflow: 'scroll' }}>
          <Document
            loading="Đang đọc file..."
            noData="Không hiển thị được nội dung"
            file={file}
            onLoadSuccess={({ numPages: pages }) => setNumPages(pages)}
            onLoadError={({ message }) =>
              Notification.warn(`Không hiển thị được nội dung: ${message}`)
            }
          >
            <Page
              loading="Đang tải trang..."
              pageNumber={pageNumber}
              renderMode="canvas"
              width={width}
            />
          </Document>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination
            page={pageNumber}
            count={numPages}
            onChange={(e, val) => setPageNumber(val)}
          />
        </Box>
      </Box>
    );
  return (
    <Box sx={{ height, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Typography>Không hiển thị được nội dung</Typography>
    </Box>
  );
}

FileViewer.propTypes = {
  id: PropTypes.string,
  local: PropTypes.object,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

FileViewer.defaultProps = {
  id: '',
  local: undefined,
};

export default FileViewer;
