import { IconButton } from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React from 'react';

function RowAction({ onClick, Icon, color }) {
  return (
    <IconButton size="small" onClick={onClick} color={color}>
      <Icon size={18} />
    </IconButton>
  );
}

RowAction.propTypes = {
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.elementType,
  color: PropTypes.string,
};

RowAction.defaultProps = {
  color: 'inherit',
};

export default RowAction;
