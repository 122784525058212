import { Notification } from '@xbotvn/mui';
import { cloneDeep, set, unset } from 'lodash';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { graphQLCaller, uploadFiles } from '../../libs/backend';
import { DOCUMENT } from './constants';

function* update(data = {}) {
  yield put({
    type: DOCUMENT.update,
    data,
  });
}

function* removeDocument({ onSuccess }) {
  const { id, data } = (yield select())?.document ?? {};
  const { department, unit } = data || {};
  if (id && department && unit) {
    try {
      yield graphQLCaller(
        'documents',
        `
        mutation removeDocument($id: ID!, $unitID: String!, $department: String!) {
          removeDocument(id: $id, unitID: $unitID, department: $department)
        }
      `,
        {
          id,
          unitID: unit,
          department,
        }
      );
      Notification.success('Xoá văn bản thành công.');
      yield put({
        type: DOCUMENT.clear,
      });
      if (onSuccess) onSuccess();
    } catch ({ message }) {
      yield* update();
      Notification.error(message);
    }
  } else {
    yield put({
      type: DOCUMENT.clear,
    });
    if (onSuccess) onSuccess();
  }
}

function* getDocument({ id, auto, skipCount, onComplete }) {
  if (!id) {
    yield* update(auto);
  } else {
    const unitID = (yield select())?.user?.unit?.id ?? '';
    try {
      const { document } = yield graphQLCaller(
        'documents',
        `{
      document(id: "${id}", skipCount: ${skipCount}, unitID: "${unitID}") {
        unit
        docType
        category
        classified
        department
        docNo
        other
        authority
        signerPosition
        date
        summary
        file
        publicFields
        properties {
          code
          value
        }
        views
        editTimes
        downloads
        signed
        fileCatalog
        fileNotation
        docOrdinal
        toOrgan
        priority
        issuedAmount
        dueDate
        arrivalDate
        arrivalNumber
        toPlaces
        traceHeaderList
        isPublic
        secure
        handler
        expiredDate
        latest {
          user
          time
        }
        comments {
          email
          content
          time
        }
      }
    }`
      );
      yield* update(document || {});
      if (!document) onComplete();
    } catch ({ message }) {
      Notification.error(message);
      yield* update();
    }
  }
}

function* updateDocument({ id, data, upload, onSuccess }) {
  const unitID = (yield select())?.user?.unit?.id ?? '';
  const requiredProperties = (
    (yield select())?.catalogs?.app?.docTypes?.data?.[data?.docType]?.properties ?? []
  )
    .filter(({ required }) => required)
    .map(({ code, name }) => ({ field: code, message: name }));
  if (unitID) {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { comments, latest, editTimes, ...updateContent } = cloneDeep(data || {});
    try {
      const requiredFields = [
        { field: 'department', message: 'Phòng ban' },
        { field: 'docType', message: 'Tên văn bản' },
        { field: 'date', message: 'Ngày ban hành' },
        { field: 'docNo', message: 'Số/Ký hiệu' },
      ];
      const failed =
        requiredFields.find(({ field }) => !updateContent[field]) ||
        requiredProperties.find(
          ({ field }) =>
            !(data?.properties ?? []).find((property) => property.code === field)?.value
        );
      if (failed) {
        onSuccess('', failed);
        Notification.warn(`${failed.message} không được bỏ trống.`);
        yield* update();
      } else {
        unset(updateContent, 'views');
        unset(updateContent, 'downloads');
        const files = upload
          ? yield uploadFiles(
              {
                ai: true,
                unitID,
                department: data.department,
              },
              [upload]
            )
          : {};
        if (files.length) updateContent.file = files[0]?.id ?? '';
        let actualID = id;
        if (id) {
          yield graphQLCaller(
            'documents',
            `
        mutation updateDocument($id: ID!, $document: DocumentInput!) {
          updateDocument(id: $id, document: $document)
        }
      `,
            {
              id,
              document: updateContent,
            }
          );
        } else {
          const { insertDocument } = yield graphQLCaller(
            'documents',
            `
        mutation insertDocument($document: DocumentInput!) {
          insertDocument(document: $document)
        }
      `,
            {
              document: {
                ...updateContent,
                unit: unitID,
              },
            }
          );
          actualID = insertDocument;
        }
        if (id) updateContent.editTimes = (editTimes || 0) + 1;
        yield* update(updateContent);
        Notification.success('Cập nhật văn bản thành công.');
        if (onSuccess) onSuccess(actualID);
      }
    } catch ({ message }) {
      yield* update();
      Notification.error(message);
      if (onSuccess) onSuccess();
    }
  } else yield* update();
}

function* writeComment({ documentID, comment }) {
  const unitID = (yield select()).user.unit?.id ?? '';
  const { data } = (yield select())?.document ?? {};
  const newData = cloneDeep(data);
  const comments = cloneDeep(data?.comments ?? []);
  try {
    yield graphQLCaller(
      'documents',
      `
        mutation writeComment($unitID: String!, $documentID: String!, $comment: CommentInput) {
          writeComment(unitID: $unitID, documentID: $documentID, comment: $comment)
        }
      `,
      {
        unitID,
        documentID,
        comment,
      }
    );
    comments.push(comment);
    set(newData, 'comments', comments);
    yield* update(newData);
  } catch ({ message }) {
    Notification.error(message);
    yield* update();
  }
}

export const handleGetDocument = (id, auto = {}, skipCount = false, onComplete) => ({
  type: DOCUMENT.handlers.get,
  id,
  auto,
  skipCount,
  onComplete,
});

export const handleChange = (data) => ({
  type: DOCUMENT.update,
  data,
});

export const handleUpdateDocument = (id, data, upload, onSuccess) => ({
  type: DOCUMENT.handlers.update,
  id,
  data,
  upload,
  onSuccess,
});

export const handleRemoveDocument = (onSuccess) => ({
  type: DOCUMENT.handlers.remove,
  onSuccess,
});

export const handleClearDocument = () => ({
  type: DOCUMENT.clear,
});

export const handleWriteComment = (documentID, comment, onComplete) => ({
  type: DOCUMENT.handlers.writeComment,
  documentID,
  comment,
  onComplete,
});

export default function* saga() {
  yield all([
    yield takeLatest(DOCUMENT.handlers.get, getDocument),
    yield takeLatest(DOCUMENT.handlers.update, updateDocument),
    yield takeLatest(DOCUMENT.handlers.remove, removeDocument),
    yield takeLatest(DOCUMENT.handlers.writeComment, writeComment),
  ]);
}
