import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas-react17-compatible';

function SignatureDrawer({ onClose, onNext }) {
  const canvasRef = useRef(null);

  return (
    <Dialog
      open
      onClose={(e, reason) => reason !== 'backdropClick' && onClose()}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle onClose={onClose}>Tạo chữ ký</DialogTitle>
      <DialogContent>
        <Box sx={{ padding: 1, border: '1px dashed #0083ff' }}>
          <SignatureCanvas
            canvasProps={{ width: 500, height: 300 }}
            ref={(ref) => {
              canvasRef.current = ref;
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1}>
          <Button onClick={() => canvasRef.current.clear()}>Đặt lại</Button>
          <Button
            color="primary"
            onClick={() => {
              onNext(canvasRef.current.getTrimmedCanvas().toDataURL('image/png'));
              onClose();
            }}
          >
            Tiếp tục
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

SignatureDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default SignatureDrawer;
