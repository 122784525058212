import { Notification } from '@xbotvn/mui';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { graphQLCaller } from '../../libs/backend';
import { SEARCH } from './constants';

export function* update(documents = []) {
  yield put({
    type: SEARCH.fetch,
    documents,
  });
}

function* searchHandler() {
  const { search, settings } = (yield select())?.search ?? {};
  const unitID = (yield select())?.user?.unit?.id ?? '';
  try {
    const { documents } = yield graphQLCaller(
      'documents',
      `query documents ($unitID: String!, $query: String, $options: SearchOptions) {
        documents(unitID: $unitID, query: $query, options: $options) {
          id
          unit
          docType
          category
          department
          docNo
          authority
          date
          summary
          other
          file
          tags
          publicFields
          classified
          isPublic
        }
      }`,
      {
        unitID,
        query: search || '',
        options: {
          limit: settings?.limit ?? 0,
        },
      }
    );
    yield* update(documents || []);
  } catch ({ message }) {
    Notification.error(message);
    yield* update();
  }
}

function* updateSettings() {
  const { search } = (yield select())?.search ?? {};
  yield put({
    type: SEARCH.handlers.search,
    search,
  });
}

export const handleSearch = (search) => ({
  type: SEARCH.handlers.search,
  search,
});

export const handleClearSearch = () => ({
  type: SEARCH.handlers.clear,
});

export const handleUpdateSettings = (settings) => ({
  type: SEARCH.handlers.settings,
  settings,
});

export default function* saga() {
  yield all([
    yield takeLatest(SEARCH.handlers.settings, updateSettings),
    yield takeLatest(SEARCH.handlers.search, searchHandler),
  ]);
}
