export const USER = {
  handlers: {
    login: 'USER_LOGIN_HANDLER',
    update: 'USER_UPDATE_HANDLER',
    unit: 'USER_UPDATE_UNIT_HANDLERS',
    logs: 'USER_LOGS_HANDLER',
    signOut: 'USER_SIGNOUT_HANDLER',
  },
  update: 'USER_UPDATE',
  unit: 'USER_UPDATE_GROUPS',
};

export const CATALOGS = {
  handlers: {
    get: 'CATALOGS_GET_HANDLER',
    system: 'CATALOGS_QUERY_SYSTEM_HANDLER',
    app: 'CATALOGS_QUERY_APP_HANDLER',
    doctypes: 'CATALOGS_DOCTYPE_HANDLER',
    departments: 'CATALOGS_DEPARTMENTS_HANDLER',
    department: 'CATALOGS_DEPARTMENT_HANDLER',
  },
  system: 'CATALOGS_SYSTEM',
  app: 'CATALOGS_APP',
};

export const DOCUMENT = {
  handlers: {
    get: 'DOCUMENT_GET_HANDLER',
    update: 'DOCUMENT_UPDATE_HANDLER',
    remove: 'DOCUMENT_REMOVE_HANDLER',
    writeComment: 'DOCUMENT_WRITE_COMMENT_HANDLER',
  },
  update: 'DOCUMENT_UPDATE',
  clear: 'DOCUMENT_CLEAR',
};

export const DRAFTS = {
  handlers: {
    get: 'DRAFTS_GET_HANDLER',
    update: 'DRAFTS_UPDATE_HANDLER',
    save: 'DRAFTS_SAVE_HANDLER',
    documents: 'DRAFTS_INSERT_DOCUMENTS',
  },
  reload: 'DRAFTS_RELOAD',
  update: 'DRAFTS_UPDATE',
  delete: 'DRAFTS_DELETE',
  undo: 'DRAFTS_UNDO',
};

export const STATISTICS = {
  handlers: {
    get: 'STATISTICS_GET_HANDLER',
    remove: 'STATISTICS_REMOVE_HANDLER',
    compute: 'STATISTICS_COMPUTE_HANDLER',
  },
  fetch: 'STATISTICS_FETCH',
  update: 'STATISTICS_UPDATE',
  remove: 'STATISTICS_REMOVE',
};

export const SETTINGS = {
  update: 'UPDATE_SETTINGS',
};

export const SEARCH = {
  handlers: {
    recent: 'SEARCH_RECENT_HANDLER',
    clear: 'SEARCH_CLEAR_HANDLER',
    search: 'SEARCH_HANDLER',
    settings: 'SEARCH_SETTINGS_HANDLER',
  },
  fetch: 'SEARCH_FETCH',
};

export const NEWS = {
  handlers: {
    documents: 'NEWS_DOCUMENTS_HANDLER',
    settings: 'NEWS_SETTINGS_HANDLER',
  },
  documents: 'NEWS_DOCUMENTS',
  tags: 'NEWS_TAGS',
};

export const LOGS = {
  handlers: {
    recent: 'LOGS_RECENT_HANDLER',
    document: 'LOGS_DOCUMENT_HANDLER',
  },
  update: 'LOGS_UPDATE',
};

export const SIGN_OUT = 'SIGN_OUT';
