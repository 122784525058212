import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilSearch from '@iconscout/react-unicons/icons/uil-search';
import {
  AppBar as MAppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from '@xbotvn/mui';
import { compact, get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { handleClearDocument } from '../../redux/actions/document';
import Profile from './Profile';

const LABELS = {
  staffs: 'Nhân sự',
  departments: 'Phòng ban',
  settings: 'Cấu hình',
};

function cleanPath(pathname) {
  const root = get(compact(pathname.split('/')), 0, false);
  switch (root) {
    case 'departments':
    case 'staffs':
    case 'settings':
      return 'admins';
    case 'draft':
    case 'document':
      return 'drafts';
    default:
      return root;
  }
}

function AppBar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    user: { email, photoURL, displayName, roles },
  } = useSelector(({ user }) => ({
    user,
  }));

  const [showProfile, setShowProfile] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const [draftsAnchorEl, setDraftsAnchorEl] = useState(null);
  const [adminsAnchorEl, setAdminsAnchorEl] = useState(null);
  const [search, setSearch] = useState('');

  const searchHandler = (str = '') => {
    const parsed = new URLSearchParams(str);
    if (parsed.get('q')) setSearch(parsed.get('q'));
  };

  useEffect(() => {
    setActiveMenu(cleanPath(history.location.pathname));
    if (location.pathname.includes('search')) searchHandler(location.search);
    history.listen((location) => {
      if (location.pathname.includes('search')) searchHandler(location.search);
      setActiveMenu(cleanPath(location.pathname));
    });
  }, []);

  const tabs = useMemo(() => {
    if (roles?.xbot || roles?.admin)
      return {
        drafts: true,
        stats: true,
        admins: ['departments', 'staffs', 'settings'],
      };
    if ((roles?.departmentAdmins ?? []).length) return { drafts: true, admins: ['departments'] };
    if (roles?.clerk || (roles?.departmentClerks ?? []).length) return { drafts: true };
    return {};
  }, [roles]);

  return (
    <MAppBar
      position="sticky"
      color="transparent"
      sx={{
        zIndex: 12,
        background: 'white',
        borderRadius: 0,
        boxShadow:
          '0px 4px 8px -2px rgba(16, 24, 64, 0.1), 0px 2px 4px -2px rgba(16, 24, 64, 0.06);',
      }}
    >
      {showProfile ? <Profile onClose={() => setShowProfile(false)} /> : null}
      <Toolbar
        sx={{ display: 'flex', minHeight: '56px !important', justifyContent: 'space-between' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <Box sx={{ cursor: 'pointer' }} onClick={() => history.push('/')}>
            <img style={{ width: 24, height: 'auto' }} src="/icon-192x192.jpg" alt="logo" />
          </Box>
          <TextField
            sx={{ width: 350 }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => history.push(`/search?q=${search.replace(/  +/g, ' ')}`)}
                  >
                    <UilSearch size={18} />
                  </IconButton>
                </InputAdornment>
              ),
              onKeyDown: (e) => {
                if (e.keyCode === 13) history.push(`/search?q=${search.replace(/  +/g, ' ')}`);
              },
            }}
          />
        </Box>
        <Stack spacing={1} direction="row" sx={{ marginRight: 3 }}>
          <Tabs value={activeMenu}>
            {tabs.drafts ? (
              <Tab
                key="drafts"
                label={
                  <Box
                    sx={{ display: 'flex', alignItems: 'center' }}
                    onClick={(e) => setDraftsAnchorEl(e.currentTarget)}
                  >
                    Số hóa
                    <UilAngleDown />
                  </Box>
                }
                value="drafts"
              />
            ) : null}
            {tabs.stats ? (
              <Tab
                key="stats"
                label="Thống kê"
                value="stats"
                onClick={() => history.push('/stats')}
              />
            ) : null}
            {tabs.admins ? (
              <Tab
                key="admins"
                label={
                  <Box
                    sx={{ display: 'flex', alignItems: 'center' }}
                    onClick={(e) => setAdminsAnchorEl(e.currentTarget)}
                  >
                    Quản trị
                    <UilAngleDown />
                  </Box>
                }
                value="admins"
              />
            ) : null}
          </Tabs>
          <Menu
            anchorEl={draftsAnchorEl}
            open={Boolean(draftsAnchorEl)}
            onClose={() => setDraftsAnchorEl(null)}
            sx={{ marginTop: 1 }}
          >
            <MenuItem
              onClick={() => {
                dispatch(handleClearDocument());
                history.push('/refresh/document/');
                setDraftsAnchorEl(null);
              }}
            >
              Một văn bản
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push('/drafts');
                setDraftsAnchorEl(null);
              }}
            >
              Nhiều văn bản
            </MenuItem>
          </Menu>
          <Menu
            anchorEl={adminsAnchorEl}
            open={Boolean(adminsAnchorEl)}
            onClose={() => setAdminsAnchorEl(null)}
            sx={{ marginTop: 1 }}
          >
            {(tabs.admins || []).map((tab) => (
              <MenuItem
                key={tab}
                onClick={() => {
                  history.push(`/${tab}`);
                  setAdminsAnchorEl(null);
                }}
              >
                {LABELS[tab]}
              </MenuItem>
            ))}
          </Menu>
        </Stack>
        {email ? (
          <Stack
            spacing={1}
            direction="row"
            sx={{ alignItems: 'center', cursor: 'pointer' }}
            onClick={() => setShowProfile(true)}
          >
            <Avatar alt={displayName} src={photoURL} />
            <Typography>{displayName || email}</Typography>
          </Stack>
        ) : null}
        {activeMenu !== 'login' && !email ? (
          <Button onClick={() => history.push('/login')}>Đăng nhập</Button>
        ) : null}
      </Toolbar>
    </MAppBar>
  );
}

export default AppBar;
