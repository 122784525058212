import { Notification } from '@xbotvn/mui';
import { clone, unset } from 'lodash';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { HOSTS } from '../../config';
import { callAPI, graphQLCaller } from '../../libs/backend';
import { CATALOGS } from './constants';

function* query(category, id) {
  yield put({
    type: CATALOGS.handlers[category],
    id,
  });
}

function* update(category, id, data) {
  yield put({
    type: CATALOGS[category],
    id,
    data,
  });
}

function* querySystemCatalogs() {
  try {
    yield* query('system', 'settings');
    const settings = yield callAPI('api/settings');
    yield* update('system', 'settings', settings);
    const cities = yield callAPI('api/cities');
    yield* update('system', 'cities', cities);
  } catch ({ message }) {
    Notification.error(message);
    yield* update('system', 'settings', {});
  }
  try {
    yield* query('system', 'product');
    const product = yield callAPI('api/product');
    yield* update('system', 'product', product);
  } catch ({ message }) {
    Notification.error(message);
    yield* update('system', 'product', {});
  }
}

function* queryAppCatalogs(unitID) {
  try {
    yield* query('app', 'docTypes');
    const { catalogs } = yield graphQLCaller(
      'catalogs',
      `{
      catalogs(unitID: "${unitID}") {
        doctypes {
          id
          categories
          shorts
          name
          properties {
            code
            name
            order
            type
            required
            isPublic
          }
        }
      }
    }`
    );
    yield* query('app', 'departments');
    const { departments } = yield graphQLCaller(
      'departments',
      `{
      departments(unitID: "${unitID}") {
        id
        name
        shorts
        admins
        staffs
      }
    }`
    );
    const docTypes = {};
    (catalogs?.doctypes ?? []).forEach(({ id, ...rest }) => {
      docTypes[id] = rest;
    });
    yield* update('app', 'docTypes', docTypes);
    yield* update('app', 'departments', departments || []);
  } catch ({ message }) {
    Notification.error(message);
    yield* update('app', 'docTypes', []);
    yield* update('app', 'departments', []);
  }
}

function* queryCatalogs() {
  let activeUnit = '';
  Object.entries(HOSTS).forEach(([domain, { unit, title }]) => {
    if (window.location.href.indexOf(domain) !== -1) {
      activeUnit = unit;
      document.title = title;
    }
  });
  yield* querySystemCatalogs();
  yield* queryAppCatalogs(activeUnit);
}

export const handleUpdateCatalog = (id, path, data) => ({
  type: CATALOGS.handlers.autocomplete,
  id,
  path,
  data,
});

export function* updateDocTypes({ doctypes, onComplete }) {
  const unitID = (yield select())?.user?.unit?.id ?? '';
  const data = clone((yield select()).catalogs?.app?.docTypes?.data ?? {});

  if (unitID) {
    try {
      doctypes.forEach(({ id, ...rest }) => {
        if (Object.keys(rest).length) data[id] = rest;
        else unset(data, id);
      });
      yield graphQLCaller(
        'catalogs',
        `
      mutation updateDocTypes($unitID: ID!, $doctypes: [DocTypeInput]!) {
        updateDocTypes(unitID: $unitID, doctypes: $doctypes)
      }
    `,
        {
          unitID,
          doctypes: doctypes.map((values, id) => ({ id, ...values })),
        }
      );
      yield* update('app', 'docTypes', data);
      Notification.success('Cập nhật cấu hình văn bản thành công.');
      onComplete();
    } catch ({ message }) {
      yield* update('app', 'docTypes', data);
      Notification.error(message);
    }
  } else yield* update('app', 'docTypes', data);
}

export function* updateDepartment({ department, id, onComplete }) {
  const unitID = (yield select())?.user?.unit?.id ?? '';
  const data = clone((yield select()).catalogs?.app?.departments?.data ?? []);

  try {
    yield graphQLCaller(
      'departments',
      `
      mutation updateDepartment($unitID: String!, $id: ID!, $department: DepartmentInput!) {
        updateDepartment(unitID: $unitID, id: $id, department: $department)
      }
    `,
      {
        unitID,
        id,
        department,
      }
    );
    const cleaned = data.filter((dep) => id !== dep.id);
    const { id: departmentID, unit, ...rest } = department;
    if (rest) {
      cleaned.push({
        id: departmentID,
        unit,
        ...rest,
      });
    }
    yield* update('app', 'departments', cleaned);
    Notification.success('Cập nhật phòng ban thành công.');
    if (onComplete) onComplete();
  } catch ({ message }) {
    yield* update('app', 'departments', data);
    Notification.error(message);
  }
}

export function* updateDepartments({ departments, onComplete }) {
  const unitID = (yield select())?.user?.unit?.id ?? '';
  const data = clone((yield select()).catalogs?.app?.departments?.data ?? []);

  try {
    yield graphQLCaller(
      'departments',
      `
      mutation updateDepartments($unitID: String!, $departments: [DepartmentInput]!) {
        updateDepartments(unitID: $unitID, departments: $departments)
      }
    `,
      {
        unitID,
        departments,
      }
    );
    const cleaned = data.filter(
      (department) => !departments.find(({ id }) => id === department.id)
    );
    departments.forEach((department) => {
      const { id, unit, ...rest } = department;
      if (rest) {
        cleaned.push({
          id,
          unit,
          ...rest,
        });
      }
    });
    yield* update('app', 'departments', cleaned);
    Notification.success('Cập nhật phòng ban thành công.');
    if (onComplete) onComplete();
  } catch ({ message }) {
    yield* update('app', 'departments', data);
    Notification.error(message);
  }
}

export const handleUpdateDocTypes = (doctypes, onComplete) => ({
  type: CATALOGS.handlers.doctypes,
  doctypes,
  onComplete,
});

export const handleUpdateDepartments = (departments, onComplete) => ({
  type: CATALOGS.handlers.departments,
  departments,
  onComplete,
});
export const handleUpdateDepartment = (department, id, onComplete) => ({
  type: CATALOGS.handlers.department,
  department,
  id,
  onComplete,
});

export const handleGetCatalogs = () => ({
  type: CATALOGS.handlers.get,
});

export default function* saga() {
  yield all([
    yield takeLatest(CATALOGS.handlers.get, queryCatalogs),
    yield takeEvery(CATALOGS.handlers.doctypes, updateDocTypes),
    yield takeEvery(CATALOGS.handlers.department, updateDepartment),
    yield takeEvery(CATALOGS.handlers.departments, updateDepartments),
  ]);
}
