import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

function RefreshRouter({ path }) {
  return (
    <Route
      path={path}
      component={({ history, location, match }) => {
        history.replace({
          ...location,
          pathname: location.pathname.substring(match.path.length),
        });
        return null;
      }}
    />
  );
}

RefreshRouter.propTypes = {
  path: PropTypes.string.isRequired,
};

export default RefreshRouter;
