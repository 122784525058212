import { combineReducers } from 'redux';

import catalogs from './catalogs';
import document from './document';
import drafts from './drafts';
import news from './news';
import search from './search';
import settings from './settings';
import statistics from './statistics';
import user from './user';

const rootReducer = combineReducers({
  settings,
  catalogs,
  user,
  drafts,
  news,
  search,
  document,
  statistics,
});

export default rootReducer;
