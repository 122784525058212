import { SEARCH, SIGN_OUT } from '../actions/constants';

const initialState = {
  search: '',
  settings: {
    limit: 0,
  },
  handling: false,
};

export default function search(state = initialState, action) {
  const { search: searchContent, documents, settings } = action;

  switch (action.type) {
    case SEARCH.handlers.search:
      return {
        ...state,
        search: searchContent,
        handling: true,
      };
    case SEARCH.handlers.clear:
      return initialState;
    case SEARCH.fetch:
      return {
        ...state,
        documents,
        handling: false,
      };
    case SEARCH.handlers.settings:
      return {
        ...state,
        settings,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
