import { Notification } from '@xbotvn/mui';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { graphQLCaller } from '../../libs/backend';
import { STATISTICS } from './constants';

function* fetch(data = []) {
  const statistics = {};
  data.forEach(({ id, ...rest }) => {
    statistics[id] = rest;
  });
  yield put({
    type: STATISTICS.fetch,
    data: statistics,
  });
}

function* update(id, data = {}) {
  yield put({
    type: STATISTICS.update,
    id,
    data,
  });
}

function* getStatistics() {
  const id = (yield select())?.user?.unit?.id ?? '';

  try {
    const { statistics } = yield graphQLCaller(
      'statistics',
      `{
        statistics(unitID: "${id}") {
          id
          name
          source
          from
          to
          total
          docTypes {
            id
            count
          }
          departments {
            id
            count
          }
          categories {
            id
            count
          }
          tags {
            id
            count
          }
          authorities {
            id
            count
          }
          pages {
            id
            count
          }
          documents {
            id
            documents {
              id
              docNo
              category
              docType
              properties {
                code
                value
              }
            }
          }
          filter {
            docTypes
            categories
            properties {
              code
              value
            }
          }
          files
          latest {
            user
            time
          }
        }
      }`
    );
    yield* fetch(statistics);
  } catch ({ message }) {
    Notification.error(message);
    yield* fetch();
  }
}

function* computeStatistics({ source, from, to, id, name, filter, onSuccess }) {
  const unitID = (yield select())?.user?.unit?.id ?? '';
  const filterInput = {};
  Object.entries(filter).forEach(([key, values]) => {
    if (key === 'properties') {
      filterInput[key] = Object.entries(values).map(([code, value]) => ({ code, value }));
    } else filterInput[key] = values;
  });
  if (unitID) {
    try {
      const { computeStatistic } = yield graphQLCaller(
        'statistics',
        `
          mutation computeStatistic($unitID: String!, $source: String!, $from: Float!, $to: Float!, $id: ID, $name: String, $filter: filterInput) {
            computeStatistic(unitID: $unitID, source: $source, from: $from, to: $to, id: $id, name: $name, filter: $filter) {
              id
              name
              source
              from
              to
              total
              docTypes {
                id
                count
              }
              departments {
                id
                count
              }
              categories {
                id
                count
              }
              tags {
                id
                count
              }
              authorities {
                id
                count
              }
              pages {
                id
                count
              }
              documents {
                id
                documents {
                  id
                  docNo
                  category
                  docType
                  properties {
                    code
                    value
                  }
                }
              }
              files
              latest {
                user
                time
              }
              filter {
                docTypes
                categories
                properties {
                  code
                  value
                }
              }
            }
          }
        `,
        {
          unitID,
          source,
          from,
          to,
          id,
          name,
          filter: filterInput,
        }
      );
      const { id: newID, ...rest } = computeStatistic || {};
      yield* update(newID, rest);
      Notification.success('Cập nhật thành công.');
      if (onSuccess) onSuccess({ id: newID });
    } catch ({ message }) {
      yield* update();
      Notification.error(message);
    }
  } else yield* update();
}

function* removeStatistic({ id, onSuccess }) {
  const unitID = (yield select())?.user?.unit?.id ?? '';
  if (unitID) {
    try {
      yield graphQLCaller(
        'statistics',
        `
          mutation removeStatistic($unitID: String!, $id: ID!) {
            removeStatistic(unitID: $unitID, id: $id)
          }
        `,
        {
          unitID,
          id,
        }
      );
      yield put({
        type: STATISTICS.remove,
        id,
      });
      Notification.success('Xoá thành công.');
      if (onSuccess) onSuccess();
    } catch ({ message }) {
      yield* update();
      Notification.error(message);
    }
  } else yield* update();
}

export const handleGetStatistics = () => ({
  type: STATISTICS.handlers.get,
});

export const handleComputeStatistics = (source, from, to, id, name, filter, onSuccess) => ({
  type: STATISTICS.handlers.compute,
  id,
  source,
  from,
  to,
  name,
  filter,
  onSuccess,
});

export const handleRemoveStatistics = (id, onSuccess) => ({
  type: STATISTICS.handlers.remove,
  id,
  onSuccess,
});

export default function* saga() {
  yield all([
    yield takeLatest(STATISTICS.handlers.get, getStatistics),
    yield takeEvery(STATISTICS.handlers.compute, computeStatistics),
    yield takeEvery(STATISTICS.handlers.remove, removeStatistic),
  ]);
}
