import { NEWS, SIGN_OUT } from '../actions/constants';

const initialState = {
  settings: {
    documents: 'today',
  },
  handling: false,
};

export default function news(state = initialState, action) {
  const { documents, settings, tags } = action;

  switch (action.type) {
    case NEWS.handlers.settings:
      return {
        ...state,
        settings,
      };
    case NEWS.handlers.documents:
      return {
        ...state,
        handling: true,
      };
    case NEWS.documents:
      return {
        ...state,
        documents,
        handling: false,
      };
    case NEWS.tags:
      return {
        ...state,
        tags,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
