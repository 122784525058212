import { all } from 'redux-saga/effects';

import catalogsSaga from './catalogs';
import documentsSaga from './document';
import draftsSaga from './drafts';
import newsSaga from './news';
import searchsSaga from './search';
import settingsSaga from './settings';
import statsSaga from './statistics';
import userSaga from './user';

export default function* rootSaga() {
  yield all([
    userSaga(),
    settingsSaga(),
    catalogsSaga(),
    draftsSaga(),
    newsSaga(),
    searchsSaga(),
    documentsSaga(),
    statsSaga(),
  ]);
}
