import { clone, unset } from 'lodash';

import { SIGN_OUT, STATISTICS } from '../actions/constants';

const initialState = {};

export default function statistics(state = initialState, action) {
  const { type, data, id } = action;

  switch (type) {
    case STATISTICS.handlers.get:
    case STATISTICS.handlers.update:
    case STATISTICS.handlers.remove:
    case STATISTICS.handlers.compute:
      return {
        ...state,
        handling: true,
      };
    case STATISTICS.fetch:
      return {
        data,
        handling: false,
      };
    case STATISTICS.update:
      return {
        data: {
          ...(state.data ?? {}),
          [id]: data,
        },
        handling: false,
      };
    case STATISTICS.remove:
      // eslint-disable-next-line no-case-declarations
      const cloned = clone(state.data);
      unset(cloned, id);
      return {
        data: cloned,
        handling: false,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
