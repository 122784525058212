import { SIGN_OUT, USER } from '../actions/constants';

const initialState = {
  authorized: false,
};

export default function user(state = initialState, action) {
  const { type, admins, staffs, ...rest } = action;

  switch (type) {
    case USER.handlers.login:
    case USER.handlers.signOut:
      return {
        authorized: false,
      };
    case USER.handlers.update:
    case USER.handlers.groups:
      return {
        ...state,
        handling: true,
      };
    case USER.update:
      return {
        ...state,
        ...rest,
        handling: false,
      };
    case USER.unit:
      return {
        ...state,
        unit: {
          ...(state.unit ?? {}),
          admins,
          staffs,
        },
        handling: false,
      };
    case SIGN_OUT:
      return {
        authorized: true,
      };
    default:
      return state;
  }
}
