const BACKEND_DOMAIN = (() => {
  if (window.location.hostname.indexOf('xbot.com.vn') !== -1) return 'xbot.com.vn';
  return 'back3nd.xyz';
})();

export const BACKEND = (() => {
  switch (process.env.REACT_APP_STAGE) {
    case 'development':
      return 'http://localhost:5001';
    case 'qa':
      return 'https://qadbot.back3nd.xyz';
    default:
      return `https://dbots.${BACKEND_DOMAIN}`;
  }
})();

export const AIO = (() => {
  switch (process.env.REACT_APP_STAGE) {
    case 'development':
    case 'qa':
      return 'https://qaaio.back3nd.xyz';
    default:
      return `https://aios.${BACKEND_DOMAIN}`;
  }
})();

export const NOTIFICATIONS = `${AIO}/api/notifications`;

export const RESOURCES = `https://resources.${BACKEND_DOMAIN}`;

export const HOSTS = {
  localhost: {
    unit: '67_661',
    title: 'DBOT',
    useExtraFeatures: true,
  },
  demo: {
    unit: '10_080',
    title: 'DBOT',
    useExtraFeatures: true,
  },
  qa: {
    unit: '67_661',
    title: 'DBOT',
    useExtraFeatures: true,
  },
  dakrlap: {
    unit: '67_666',
    title: "Đắk R'Lấp",
  },
  dakglong: {
    unit: '67_661',
    title: 'Đắk Glong',
  },
  daksong: {
    unit: '67_665',
    title: 'Đắk Song',
    useExtraFeatures: true,
  },
};

export const CATEGORIES = {
  hanhchinh: 'Văn bản hành chính',
  dang: 'Văn bản của Đảng',
  quyphamphapluat: 'Văn bản quy phạm pháp luật',
  chuyennganh: 'Văn bản chuyên ngành',
};

export const PROPTYPES = {
  string: 'Chuỗi',
  memo: 'Đoạn văn bản',
  number: 'Số',
  date: 'Ngày/Tháng/Năm',
  bool: 'Có/Không',
};

export const RECENTS = {
  today: 'Hôm nay',
  yesterday: 'Hôm qua',
  last7days: '7 ngày qua',
  last30days: '30 ngày qua',
};

export const LOGTYPES = {
  file: 'File',
  document: 'Số hoá',
  settings: 'Cấu hình',
  permissions: 'Phân quyền',
};

export const SOURCES = {
  author: 'Ngày tạo',
  date: 'Ngày ban hành',
};

export const PRIORITIES = ['Thường', 'Khẩn cấp', 'Hỏa tốc'];

export const CLASSES = {
  out: 'Văn bản đi',
  in: 'Văn bản đến',
};
