import './libs/firebase';

import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Notification,
  Typography,
  Waiting,
} from '@xbotvn/mui';
import { createTheme, ThemeProvider } from '@xbotvn/mui/styles';
import { lightTheme } from '@xbotvn/mui/themes';
import axios from 'axios';
import { isAfter } from 'date-fns';
import {
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailLink,
} from 'firebase/auth';
import Parser from 'html-react-parser';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch as RouterSwitch } from 'react-router-dom';

import packageJson from '../package.json';
import AuthorizedRoute from './AuthorizedRoute';
import { AppBar } from './components';
import { NOTIFICATIONS } from './config';
import { handleGetCatalogs } from './redux/actions/catalogs';
import { handleUpdateSettings } from './redux/actions/settings';
import { handleUserSignedIn } from './redux/actions/user';
import RefreshRoute from './RefreshRoute';

const Home = React.lazy(() => import('./screens/Home'));
const Departments = React.lazy(() => import('./screens/Departments'));
const Statistics = React.lazy(() => import('./screens/Statistics'));
const Document = React.lazy(() => import('./screens/Document'));
const Staffs = React.lazy(() => import('./screens/Staffs'));
const Settings = React.lazy(() => import('./screens/Settings'));
const Drafts = React.lazy(() => import('./screens/Drafts'));
const Login = React.lazy(() => import('./screens/Login'));
const NotFound = React.lazy(() => import('./screens/NotFound'));

export default function App() {
  const dispatch = useDispatch();
  const { authorized, logged, product, expire } = useSelector(({ user, catalogs }) => ({
    authorized: user?.authorized ?? false,
    logged: user.email,
    product: catalogs?.system?.product?.data ?? {},
    expire: user.unit?.expire ?? null,
  }));

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const cached = localStorage.getItem('settings');
    if (cached) {
      const parsed = JSON.parse(cached);
      dispatch(handleUpdateSettings(parsed));
      if (parsed.dark) {
        document.body.style.backgroundColor = '#30404d';
      }
    }
    if (isSignInWithEmailLink(getAuth(), window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        Notification.warn('Link đã được mở trên một thiết bị khác. Xin vui lòng đăng nhập lại.');
      } else {
        signInWithEmailLink(getAuth(), email, window.location.href)
          .then(() => {
            window.localStorage.removeItem('emailForSignIn');
          })
          .catch((err) => {
            Notification.error(err.message);
          });
      }
    }
    return onAuthStateChanged(getAuth(), (user) => {
      dispatch(handleUserSignedIn(user));
    });
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_STAGE !== 'development' && logged) {
      axios
        .post(NOTIFICATIONS, { product: 'dbot' })
        .then((results) => {
          setNotifications(results?.data ?? []);
        })
        .catch();
    }
    dispatch(handleGetCatalogs());
  }, [logged]);

  const expired = useMemo(() => expire && isAfter(new Date(), expire), [expire]);

  const ready = useMemo(() => authorized, [authorized]);

  const theme = createTheme({
    ...lightTheme,
    components: {
      ...lightTheme.components,
      MuiAutocomplete: {
        styleOverrides: {
          ...(lightTheme?.components?.MuiAutocomplete?.styleOverrides ?? {}),
          popper: {
            zIndex: 1333,
          },
        },
      },
    },
  });

  return ready ? (
    <ThemeProvider theme={theme}>
      {logged && notifications.length ? (
        <Dialog
          onClose={() => setNotifications((prev) => prev.shift())}
          open
          maxWidth="md"
          fullWidth
        >
          <DialogTitle onClose={() => setNotifications((prev) => prev.shift())}>
            {notifications[0].title}
          </DialogTitle>
          <DialogContent>{Parser(notifications[0].content)}</DialogContent>
        </Dialog>
      ) : null}
      {expired ? (
        <Backdrop style={{ zIndex: 999999999999 }} open>
          <Alert severity="warning" sx={{ maxWidth: '70%' }}>
            <AlertTitle>Hết hạn sử dụng</AlertTitle>
            <Typography component="p" variant="body2" sx={{ marginTop: 1 }}>
              Xin chào Quý Khách Hàng,
            </Typography>
            <Typography component="p" variant="body2" sx={{ marginTop: 1, marginBottom: 1 }}>
              Phần mềm Quản lý tài liệu số hóa văn bản của Quý đơn vị mình đang sử dụng, đã hết hạn
              server nên không thể truy cập vào hệ thống. Vui lòng liên hệ làm thủ tục gia hạn
              server.
            </Typography>
            <Typography component="p" variant="body2" sx={{ marginBottom: 1 }}>
              Thông tin liên hệ hỗ trợ: 0909 422 898
            </Typography>
            <Typography component="p" variant="body2">
              Cám ơn Quý Khách Hàng. Trân trọng!
            </Typography>
          </Alert>
        </Backdrop>
      ) : null}
      <BrowserRouter>
        <Suspense fallback={<Waiting />}>
          <AppBar />
          <Box>
            <RouterSwitch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/search">
                <Home />
              </Route>
              <Route exact path="/document/:id?">
                <Document />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <AuthorizedRoute exact path="/departments" component={Departments} />
              <AuthorizedRoute exact path="/staffs" component={Staffs} />
              <AuthorizedRoute exact path="/settings" component={Settings} />
              <AuthorizedRoute exact path="/stats" component={Statistics} />
              <AuthorizedRoute exact path="/drafts" component={Drafts} />
              <RefreshRoute path="/refresh" />
              <Route component={() => <NotFound />} />
            </RouterSwitch>
          </Box>
        </Suspense>
      </BrowserRouter>
      <Box
        zIndex={10}
        sx={{
          height: 56,
          position: 'fixed',
          paddingLeft: '50px',
          paddingRight: '50px',
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: 'white',
        }}
      >
        <Typography variant="caption">
          {`DBOT v${packageJson.version} - © 2020, STEDU Technology JSC. All Rights Reserved.`}
        </Typography>
        {logged ? (
          <Box display="flex">
            <Button size="small" variant="text" style={{ marginRight: 20 }}>
              Hỗ trợ từ xa
            </Button>
            {product.guide ? (
              <Button
                variant="text"
                onClick={() => window.open(product.guide, 'Hướng dẫn sử dụng', 'location:no')}
                style={{ marginRight: 30 }}
                size="small"
              >
                Sách hướng dẫn
              </Button>
            ) : null}
          </Box>
        ) : null}
      </Box>
      <Notification.Container
        position="top-right"
        autoClose={3000}
        hideProgressBar
        pauseOnHover
        pauseOnFocusLoss
        closeOnClick
        draggable
        draggablePercent={80}
      />
    </ThemeProvider>
  ) : (
    <Waiting />
  );
}
