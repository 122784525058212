import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  TextField,
  Waiting,
} from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import { handleUserSignOut, handleUserUpdate } from '../../../redux/actions/user';
import Signature from '../../Signature';
import SignatureDrawer from './SignatureDrawer';

function ProfileForm({ onClose }) {
  const dispatch = useDispatch();
  const {
    email,
    displayName: name,
    phoneNumber: phone,
    signature: initSignature,
    photoURL,
    male,
    handling,
  } = useSelector(({ user }) => user);

  const [signature, setSignature] = useState(initSignature);
  const [displayName, setDisplayName] = useState(name || '');
  const [phoneNumber, setPhoneNumber] = useState(phone || '');
  const [gender, setGender] = useState(male ? 'M' : 'F');
  const [createSignature, setCreateSignature] = useState(false);

  const genderOptions = useMemo(
    () => [
      <MenuItem key="M" value="M">
        Nam
      </MenuItem>,
      <MenuItem key="F" value="F">
        Nữ
      </MenuItem>,
    ],
    []
  );

  return (
    <Dialog open onClose={onClose} maxWidth="md">
      <DialogTitle onClose={onClose}>Thông tin tài khoản</DialogTitle>
      <DialogContent>
        {handling ? <Waiting fullscreen /> : null}
        {createSignature ? (
          <SignatureDrawer
            onClose={() => setCreateSignature(false)}
            onNext={(val) => setSignature(val)}
          />
        ) : null}
        <Box width={500} m={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label="Email" fullWidth defaultValue={email} disabled variant="outlined" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Họ và tên"
                required
                fullWidth
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                label="Số điện thoại"
                fullWidth
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Giới tính"
                fullWidth
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                select
                SelectProps={{}}
                variant="outlined"
              >
                {genderOptions}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Chữ ký điện tử</FormLabel>

                {signature ? (
                  <Signature onRemove={() => setSignature(null)} signature={signature} />
                ) : (
                  <Box sx={{ marginTop: 1, width: '100%' }}>
                    <Dropzone
                      onDrop={(files) => {
                        if (files.length) {
                          const reader = new FileReader();
                          reader.onload = () => {
                            setSignature(reader.result);
                            setSignature(reader.result);
                          };
                          reader.readAsDataURL(files[0]);
                        }
                      }}
                      accept=".jpeg, .png, .jpg"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <Button
                            color="primary"
                            variant="text"
                            style={{
                              border: '1px dashed #0083ff',
                              boxSizing: 'border-box',
                              borderRadius: '16px !important',
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '12px 16px',
                              height: 72,
                            }}
                          >
                            Upload chữ ký
                          </Button>
                        </div>
                      )}
                    </Dropzone>
                    <Button
                      variant="text"
                      fullWidth
                      sx={{ marginTop: 1 }}
                      onClick={() => setCreateSignature(true)}
                    >
                      Tạo chữ ký
                    </Button>
                  </Box>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" width={1}>
          <Button
            style={{ marginRight: 10 }}
            color="secondary"
            onClick={() => dispatch(handleUserSignOut())}
          >
            Đăng xuất
          </Button>
          <Button
            color="primary"
            onClick={() => {
              dispatch(
                handleUserUpdate(
                  {
                    displayName,
                    phoneNumber,
                    male: gender === 'M',
                    photoURL,
                    signature,
                  },
                  (err) => {
                    if (!err) onClose();
                  }
                )
              );
            }}
          >
            Cập nhật
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

ProfileForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ProfileForm;
