import { Notification } from '@xbotvn/mui';
import { getAuth, signOut } from 'firebase/auth';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { HOSTS } from '../../config';
import { auth, graphQLCaller, signedOut } from '../../libs/backend';
import { SIGN_OUT, USER } from './constants';

function* update(data = {}) {
  yield put({
    type: USER.update,
    ...data,
  });
}

function* userSignedOut() {
  signOut(getAuth());
  yield signedOut();
  yield put({
    type: SIGN_OUT,
  });
}

function loadLiveSupport(user, unit) {
  window.fcWidget.init({
    token: 'a1e32696-ff15-47e3-a3ec-44c01ddf8f0c',
    host: 'https://wchat.freshchat.com',
    locale: 'vi',
    siteId: 'DBOT',
    tags: ['dbot'],
    firstName: 'Thầy/Cô',
    lastName: user.displayName,
    email: user.email,
    faqTags: {
      tags: ['dbot'],
      filterType: 'category',
    },
    config: {
      showFAQOnOpen: false,
      headerProperty: {
        hideChatButton: true,
        appName: 'DBOT',
      },
      content: {
        placeholders: {
          search_field: 'Tìm kiếm',
          reply_field: 'Trả lời',
          csat_reply: 'Nhập nội dung đánh giá...',
        },
        actions: {
          csat_yes: 'Có',
          csat_no: 'Không',
          push_notify_yes: 'Có',
          push_notify_no: 'Không',
          tab_faq: 'Câu hỏi thường gặp',
          tab_chat: 'Hỗ trợ',
          csat_submit: 'Gửi',
        },
        headers: {
          chat: 'Hỗ trợ trực tuyến',
          chat_help: 'Xin cám ơn thầy/cô đã sử dụng hệ thống DBOT',
          faq: 'Các câu hỏi thường gặp',
          faq_help: 'Thầy/Cô có thể tìm kiếm theo nội dung',
          faq_not_available: 'Không tìm thấy',
          faq_search_not_available: 'Không tìm thấy tài liệu nào có nội dung {{query}}',
          faq_useful: 'Tài liệu này có giúp ích gì cho thầy/cô không?',
          faq_thankyou: 'Cám ơn phản hồi của thầy/cô',
          faq_message_us: 'Góp ý',
          push_notification: 'Thầy/Cô có đồng ý hiện thông báo khi có tin nhắn đến?',
          csat_question: 'Câu hỏi của thầy/cô có được trả lời không??',
          csat_yes_question: 'Thầy/cô đánh giá như thế nào về thái độ chăm sóc?',
          csat_no_question: 'Công ty có thể cải thiện gì để hỗ trợ tốt thêm không?',
          csat_thankyou: 'Cám ơn đánh giá của thầy/cô',
          csat_rate_here: 'Gửi đánh giá',
          channel_response: {
            offline:
              'Hiện không có nhân viên nào đang trực tổng đài. Thầy/Cô vui lòng để lại tin nhắn tại đây.',
            online: {
              minutes: {
                one: 'Thầy/Cô vui lòng đợi khoảng {!time!} phút',
                more: 'Thông thường sẽ mất khoảng {!time!} phút',
              },
              hours: {
                one: 'Thầy/Cô vui lòng đợi khoảng 1 tiếng',
                more: 'Thông thường sẽ mất khoảng {!time!} tiếng',
              },
            },
          },
        },
      },
    },
  });

  const gender = user.gender === 'M' ? 'Thầy' : 'Cô';

  window.fcWidget.user.setProperties({
    firstName: gender,
    lastName: user.displayName,
    email: user.email,
    unit_name: unit?.name ?? '',
    unit_id: unit?.id ?? '',
    address: '',
    provinceName: 'Không tìm thấy',
    districtName: 'Không tìm thấy',
    wardName: 'Không tìm thấy',
    user_uid: user.uid,
    log_server: 'Unkown',
  });
}

function* userSignedIn(user) {
  let activeUnit = '';
  let useExtraFeatures = false;
  Object.entries(HOSTS).forEach(([domain, { unit, title, useExtraFeatures: extraFeatures }]) => {
    if (window.location.href.indexOf(domain) !== -1) {
      activeUnit = unit;
      document.title = title;
      useExtraFeatures = extraFeatures;
    }
  });
  const { email, uid } = user;
  if (email) {
    try {
      const { roles, information, unit } = yield auth(email, uid, activeUnit);

      if (Object.keys(roles).length) {
        yield* update({
          uid,
          email,
          displayName: user.displayName ?? email,
          photoURL: user.photoURL ?? '',
          ...information,
          roles,
          unit,
          authorized: true,
        });
        loadLiveSupport(user, unit);
      } else {
        Notification.warn('Tài khoản chưa được cấp quyền sử dụng phần mềm.');
        yield userSignedOut();
      }
    } catch ({ message }) {
      Notification.error(message);
      yield* update({ email, uid, authorized: true });
    }
  } else {
    yield* update({ authorized: true, unit: { id: activeUnit, useExtraFeatures } });
  }
}

function* updateInformation({ information, onComplete }) {
  const { email } = (yield select())?.user ?? {};
  try {
    yield graphQLCaller(
      'users',
      `
      mutation updateUserInformation($information: UserInput!) {
        updateUserInformation(information: $information)
      }
    `,
      {
        information: {
          email,
          ...information,
        },
      }
    );
    yield* update(information);
    Notification.success('Cập nhật thông tin tài khoản thành công.');
    onComplete();
  } catch ({ message }) {
    yield* update();
    Notification.error(message);
    onComplete(message);
  }
}

function* updateUnit({ admins, staffs, clerks }) {
  const id = (yield select())?.user?.unit?.id ?? '';
  if (!id) yield* update();

  try {
    yield graphQLCaller(
      'units',
      `
      mutation updateUnitInformation($id: ID!, $information: UnitInput!) {
        updateUnitInformation(id: $id, information: $information)
      }
    `,
      {
        id,
        information: {
          admins,
          staffs,
          clerks,
        },
      }
    );

    yield put({
      type: USER.unit,
      admins,
      staffs,
      clerks,
    });
    Notification.success('Cập nhật thành công.');
  } catch ({ message }) {
    Notification.error(message);
    yield* update();
  }
}

export const handleUserUpdate = (information, onComplete) => ({
  type: USER.handlers.update,
  information,
  onComplete,
});

export const handleUserSignedIn = (user) => ({
  type: USER.handlers.login,
  ...user,
});

export const handleUserSignOut = () => ({
  type: USER.handlers.signOut,
});

export const handleUpdateUnit = (staffs, admins, clerks) => ({
  type: USER.handlers.unit,
  staffs,
  admins,
  clerks,
});

export default function* saga() {
  yield all([
    yield takeLatest(USER.handlers.login, userSignedIn),
    yield takeLatest(USER.handlers.update, updateInformation),
    yield takeEvery(USER.handlers.signOut, userSignedOut),
    yield takeLatest(USER.handlers.unit, updateUnit),
  ]);
}
